h1, h4{
    text-align: center;
    color: #1E3231;
}

body{
    background-color: #F6C0D0;
}

.container{
    margin-top: 20px;
    margin-bottom: 20px;
}

.quote{
    margin-right: 20px;
    margin-left: 20px;
}

.header{
    text-align: center;
}

button {
    /* border: 0; */
    font-size: 24px;
    border-radius: 0;
    background: none;
    background-color: aliceblue;
    -webkit-appearance: none;
}

.flip{
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

@media only screen and (max-width: 600px) {
    .kanye_img{
        width: 100%;
        /* margin-right: 20px;
        margin-left: 20px; */
    }
}